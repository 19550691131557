body {
  margin: 0px;
  font-family: "IBM Plex Mono", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.typebox {
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px,
    rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  border-radius: 10px;
  margin: 5px;
  padding: 8px;
  display: inline-block;
  vertical-align: top;
  height: 280px;
}

td.typebox * {
  margin-left: 10px;
  margin-right: 16px;
  padding-bottom: 4px;
  flex-grow: 1;
  flex-basis: 200;
}

.topics {
  border-collapse: separate;
  border-spacing: 2em 40px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  margin: 8px;
}

@media (max-width: 768px) {
  .topics {
    display: block;
  }

  .typebox {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    margin-top: 20px;
    height: 280px;
  }

  .typebox h3 {
    margin-bottom: 10px;
  }

  .typebox AudioPlayer {
    margin: 5px 0;
  }
}

.footer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  background-color: gray;
  height: 60px;
}

.link {
  color: white;
  padding: 6px;
}

.audio-button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #3b3b3b;
  color: #fff;
  border: none;
  cursor: pointer;
}

.audio-button:hover {
  background-color: #555;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
