.sounds-box-header {
  border: 1px solid #373d43;
  margin-top: 1rem;
  border-top-right-radius: 15px;
  padding: 15px 15px;
}

.sounds-box-wrapper {
  padding: 0;
  overflow: hidden;
}

.title {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}

.arrow-icon {
  width: 15px;
  height: 15px;
  transform: rotate(90deg);
  cursor: pointer;
}

.sounds-box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.audio-players-wrapper {
  padding: 30px 10px;
  border-bottom-right-radius: 15px;
  border-right: 1px solid #373d43;
  border-left: 1px solid #373d43;
  border-bottom: 1px solid #373d43;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.audio-players-wrapper.open {
  max-height: 1000px;
}

.audio-players-wrapper.closed {
  max-height: 0;
}

.pink-color {
  color: #d24480;
}
