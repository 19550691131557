.header-wrapper {
  width: 100%;
  background-color: #000000;
  padding: 30px 0;
}

.header-heading {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}

.speaker-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.header-button-wrapper {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
}

.header-heading-second {
  color: #75726a;
  float: left;
  margin-top: 20px;
  text-align: left;
}

.web-icon-text-wrapper {
  display: inline-block;
  margin-top: 20px;
}

.flex-wrapper {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 40px;
}

.web-icon-text-wrapper {
  display: flex;
  gap: 1rem;
}

.d-logo {
  width: 25px;
  height: 30px;
}

.underline {
  width: 100%;
  height: 1px;
  background-color: #373d43;
  margin-top: 20px;
}
