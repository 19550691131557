.max-content-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 0 25px;
}

@media (min-width: 768px) {
  .max-content-wrapper {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    padding: unset;
  }
}
