.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.audio-player-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.audio-player-container button {
  background: #fff;
  border: none;
  padding: 10px 20px;
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
  width: 120px;
  height: 100px;
  text-transform: uppercase;
  cursor: pointer;
  color: #3b3b3b;
  /* transform: skew(-21deg); */
}

.audio-player-container button:hover {
  box-shadow: inset 0 0 0 2px #6c6c6c;
}

.audio-player-container button.active {
  background: #b6cc76;
  color: #fff;
}

.audio-player-container span.audio-name {
  width: 200px;
  /* or any fixed width you desire */
  /* display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left; */
  color: #77726a;
  margin-bottom: 10px;
  font-size: 13px;
  text-align: left;
}

.audio-player-container input[type="range"] {
  margin-left: 10px;
  margin-right: 10px;
}

.audio-player-container span.audio-volume {
  /* width: 100px; */
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.lastsection {
  display: flex;
  justify-content: space-between;
  margin: 40px;
  position: relative;
  align-content: center;
}

.firsttopic {
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #ff9090;
  box-shadow: rgba(66, 9, 18, 0.25) 0px 4px 8px -2px,
    rgba(174, 50, 50, 0.08) 0px 0px 0px 1px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 40px 52px 40px 58px;
  border-radius: 10px;
  margin: 30px;
}

.thirdtopicdtopic {
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #b6cc76;
  box-shadow: rgba(9, 66, 25, 0.25) 0px 4px 8px -2px,
    rgba(30, 181, 73, 0.08) 0px 0px 0px 1px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 50px 62px 50px 68px;
  border-radius: 10px;
  margin: 30px;
}

.secondtopic {
  display: inline-block;
  vertical-align: top;
  padding: 20px;
}

#footer_logo {
  height: 160px;
}

#missing_sounds {
  padding: 10px;
}

.custom-range {
  -webkit-appearance: none;
  appearance: none;
  width: 250px;
  height: 8px;
  background: #052617;
  border-radius: 1px;
  border: 1px solid #373d43;
  outline: none;
  transition: background 0.3s ease;
}

.custom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 5px;
  height: 20px;
  background: #14975d;
  border: 2px solid #14975d;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease;
}

.custom-range-pink {
  -webkit-appearance: none;
  appearance: none;
  width: 250px;
  height: 8px;
  background: #3f1627;
  border-radius: 1px;
  border: 1px solid #373d43;
  outline: none;
  transition: background 0.3s ease;
}

.custom-range-pink::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 5px;
  height: 20px;
  background: #d24480;
  border: 2px solid #d24480;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease;
}

.gray-speaker {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.link-text {
  color: #fff;
  font-weight: 500;
  font-size: 24px;
  text-align: left;
}

.link-wrapper {
  padding-top: 40px;
  padding-bottom: 60px;
  text-align: left;
}

.link {
  color: #14975d;
  text-decoration: none;
  font-size: 24px;
  text-align: left;
}

.sound-boxes-wrapper {
  padding-top: 20px;
}

.pink-color {
  color: #d24480;
}

.filters {
  display: flex;
  overflow-y: scroll;
  list-style: none;
  gap: 15px;
  margin-top: 20px;

  scrollbar-width: none;
  -ms-overflow-style: none;

  -webkit-overflow-scrolling: touch;
}

.filters::-webkit-scrollbar {
  display: none;
}

.filter-title {
  cursor: pointer;
  color: #75726a;
  transition: color 0.3s ease;
}

.filter-title.active {
  color: #16ae6b;
}
