.footer-heading {
  color: #373d43;
  font-size: 24px;
  font-weight: 500;
  text-align: left;
}

.footer-wrapper {
  width: 100%;
  border-top: 1px solid #707070;
  display: flex;
  justify-content: space-between;
  padding: 35px 0;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.first-ul li {
  color: #76726a;
  list-style-type: none;
  font-size: 14px;
  margin-bottom: 5px;
  text-align: left;
}

.second-ul li {
  color: #373d42;
  list-style-type: none;
  font-size: 14px;
  margin-bottom: 5px;
  margin-left: 0px;
  padding-left: 0px;
  text-align: left;
}

.footer-text {
  color: #fff;
  text-align: left;
  margin-left: 5px;
}
